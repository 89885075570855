.top-10-sub-heading {
    background-color: white;
    margin-left: 9%;
    margin-right: 9%;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.player-pics {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 9%;
    margin-right: 9%;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 2px;
    border: 1px solid black;
}
  
.pics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 2px;
}

.top-10-title {
    text-align: left; 
    font-size: 1.4rem; 
    font-weight: bold;
    color: #000;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 1%;
}

.player-pic-top-10 {
    width: 100%; 
    height: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    object-fit: contain;
}

.player-pics-lists .pics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.player-pics-lists .player-pic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9.5%;
    margin: 0 1px;
}

.player-pics-lists .player-pic-top-10 {
    width: 100%; 
    height: auto;
    aspect-ratio: 1; /* Forces a square aspect ratio */
    margin: 1px 1px 2px 1px;
    overflow: hidden;
}

.top-10-sub-heading p {
    margin-top: 0px;
    margin-bottom: 2px;
}

.dropdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.dropdown {
    cursor: pointer;
    border: 1px solid #000000;
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background: white;
    position: relative;
    width: 15%;
    justify-content: center;
}

.dropdown .dropdown-arrow {
    font-size: 16px;
    position: absolute;
    right: 10px;
}

.dropdown-list {
    position: absolute;
    top: 100%; /* Positions dropdown list directly below each dropdown */
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for clarity */
}

.dropdown-item {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown.open .dropdown-arrow {
    transform: rotate(180deg);
}

.player-stat-transfers {
    font-weight: 800;
    margin: 0px;
}

.player-stat-transfers .positive {
    color: green;
}

.player-stat-transfers .negative {
    color: red;
}
  
@media (max-width: 1200px) {
    .top-10-sub-heading {
        font-size: 0.6rem;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 3px;
        margin-top: 5px;
        padding-top: 2px;
        padding-bottom: 0px;
    }

    .player-pics-lists .player-pic-container {
        width: 9.2%;
        margin: 0 1px;
    }

    .top-10-title {
        font-size: 0.7rem; 
        margin-bottom: 1px;
        margin-top: 1px;
        margin-left: 1%;
    }

    p {
        font-size: 0.6rem;
        margin-top: 1px;
        margin-bottom: 1px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .player-pics {
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 2px;
        margin-bottom: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
    }

    .player-pics-lists .player-pic-top-10 {
        margin-bottom: 2px;
        margin-top: 2px;
    }

    .pics-wrapper {
        margin-bottom: 0px;
    }

    .dropdown {
        width: 30%;
        font-size: 0.6rem;
        padding: 3px;
    }

    .dropdown-container {
        gap: 10px;
        margin-left: 3%;
        margin-right: 3%;
    }

    .dropdown .dropdown-arrow {
        position: absolute;
        right: 3px;
        font-size: 0.6rem;
    }
    
    .dropdown-list {
        font-size: 0.6rem;
    }

    .dropdown-item {
        padding: 3px;
    }
}

@media (max-width: 500px) {
    .player-stat-transfers {
        font-size: 0.5rem;
    }
}

@media (max-width: 450px) {
    .player-stat-transfers {
        font-size: 0.4rem;
    }
}
