body, html {
  margin: 0;
  padding: 0;
  background-color: rgb(177, 227, 172);
}

.app {
  text-align: center;
  color: black;
  padding-bottom: 10px;
}

.cards {
margin-top: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
