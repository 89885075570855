.content {
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header {
  font-size: x-large;
  padding-top: 2px;
  padding-left: 10%;
  padding-right: 20%;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.fdr-increase-title {
  margin-top: 10px;
}

h1 {
  font-size: 4rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

h2 {
  margin-bottom: 1px;
  margin-top: 5px;
}

h3 {
  margin-top: 0px;
}

.date-posted {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 0px;
  font-style: italic;
}

.oracle-fpl-image {
  width: 8%;
  padding-top: 10px;
  padding-left: 20%;
}

.home-sub-heading {
  font-size: 1.3rem;
  margin: 10px;
  flex: 1;
  text-align: left;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  margin-left: 9%;
  margin-right: 9%;
}

.fdr-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin: 10px;
  flex: 1;
  text-align: left;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  margin-left: 9%;
  margin-right: 9%;
}

.home-sub-heading ul {
  list-style-type: disc;
  list-style-position: inside; 
  padding-left: 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-sub-heading li {
  margin-bottom: 3px;
  margin-left: 2px; 
}

.fdr-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Adjust the maximum width as needed */
  margin: 10px auto; /* Center the container with some margin */
}

.fdr-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border: 3px solid #000000; /* Tight border around the image */
}

.fdr-image-grid {
  border: 3px solid #000000;
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image scales to fit within its container while maintaining aspect ratio */
}

.fdr-heading {
  font-size: 1.3rem;
  flex: 1; 
  text-align: left; 
  background-color: white;
  margin-left: 2%;
  margin-right: 2%;
  height: auto;
}

.fdr-heading ul {
  list-style-type: disc;
  list-style-position: inside; 
  padding-left: 2px; 
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.fdr-heading li {
  font-size: 1.3rem;
  margin-bottom: 5px;
  margin-left: 10px; 
}

.fdr-heading li::marker {
  content: "• ";
  margin-right: 5px;
}


@media (max-width: 1600px) {
  .home-sub-heading {
    margin-left: 5%;
    margin-right: 5%;
  }

  .fdr-wrapper {
    margin-left: 5%;
    margin-right: 5%;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .oracle-fpl-image {
    width: 7%;
  }
}


@media (max-width: 1200px) {
  .app-header {
    font-size: small;
    padding-left: 5%;
    padding-right: 10%;
  }

  .home-sub-heading {
    font-size: 0.6rem;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .home-sub-heading ul {
    padding-left: 1px;
    margin: 3px;
  }

  .home-sub-heading li {
    margin-left: 2px;
  }

  h1 {
    font-size: 1.6rem;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  h2 {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 1.1rem;
  }

  h3 {
    font-size: 0.7rem;
  }

  .fdr-wrapper {
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .fdr-image {
    border: 2px solid #000000;
    width: 85%;
    display: block;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .fdr-image-container {
    order: 2;
    margin: 0px;
  }

  .fdr-image-grid {
    border: 2px solid #000000;
    width: 85%;
    display: block;
    margin: 0px;
  }

  .fdr-heading {
    margin-bottom: 5px;
    order: 1;
  }

  .fdr-heading li {
    font-size: 0.6rem;
    margin-bottom: 5px;
    margin-left: 5px; 
  }

  .fdr-increase-title {
    margin-top: 5px;
  }

  .date-posted {
    font-size: 0.6rem;
    margin-bottom: 5px;
  }

  .fdr-heading ul {
    margin: 5px 0;
  }

  .oracle-fpl-image {
    width: 6%;
    padding-left: 10%;
  }
}

@media (max-width: 800px) {
  .oracle-fpl-image {
    width: 8%;
    padding-left: 8%;
  }

  .app-header {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (max-width: 420px) {
  .home-sub-heading {
    padding-left: 1%;
    padding-right: 1%;
  }

  .app-header {
    font-size: x-small;
    padding-left: 2%;
    padding-right: 2%;
  }

  .oracle-fpl-image {
    width: 15%;
  }
}
