  .fixture-sub-heading {
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    margin-left: 9%;
    margin-right: 9%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fixture-sub-heading p {
    margin-top: 0px;
    margin-bottom: 0px;
  }


  /* Add a media query for mobile devices */
@media (max-width: 1200px) { /* Adjust the max-width as needed */

  .fixture-sub-heading{
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 3%;
    margin-right: 3%;
  }
}


/* SMALL MOBILE */
@media (max-width: 420px) { 

  .fixture-sub-heading{
    font-size: 0.7rem;
    padding-left: 5%;
    padding-right: 5%;
  }
}
