.faq-page-container {
    margin-left: 9%;
    margin-right: 9%;
    font-family: Arial, sans-serif;
  }

.faq-section-container {
    margin-bottom: 30px;
}

h1 {
    font-size: 3rem;
    margin-bottom: 10px;
}
  
.faq-content {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding-left: 80px;
    padding-right: 80px;
}

.faq-question {
    font-size: 18px;
    margin: 0;
    color: #333;
}

.faq-answer {
    font-size: 18px;
    color: #555;
    margin-top: 5px;
    margin-bottom: 2px;
}

.faq-section-title {
    font-size: 24px;
    margin-bottom: 2px;
}

@media (max-width: 1200px) { 
    .faq-page-container {
        margin-left: 3%;
        margin-right: 3%;
    }

    .faq-content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-bottom: 4px;
    }

    .faq-question {
        font-size: 12px;
    }
    
    .faq-answer {
        font-size: 10px;
    }

    .faq-section-title {
        font-size: 14px;
    }

    .faq-section-container {
        margin-bottom: 10px;
    }

    h1 {
        font-size: 1.4rem;
        margin-bottom: 7px;
    }
}
  