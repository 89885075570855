.player-searcher {
    border: 2px solid black;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
    width: 50%;
}

.form {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.form-label {
    font-size: 1.2rem;
    margin-top: 5px;
}

.form-input {
    font-size: 1.2rem;
    width: 90%;
    text-align: center;
    justify-content: center;
    margin-left: 5px;
}

.submit-button {
    font-size: 1.2rem;
    width: 100%;
    margin-top: 5px;
    margin-left:10px;
}

.results-wrapper {
    width: 95%;
    margin-top: 7px; 
    margin-bottom: 10px; 
}

.results {
    background-color: rgb(255, 255, 255);
    border: 2px solid black;
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
}

.player-pic2 {
    margin-top: 2px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.stats-results, .stats-headings, .stats-paragraph {
    text-align: center;
    margin: 0;
    padding: 0;
}

.search-dropdown {
    position: absolute;
    top: 100%; 
    left: 0; 
    z-index: 999; 
    background-color: white;
    border: 1px solid #ccc;
    max-height: 500px; 
    overflow-y: auto;
    width: 94%; 
    margin-top: 0px; 
    margin-left: 4px;
}

.search-item {
    padding: 4px 0px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.search-item:hover {
    background-color: #f0f0f0;
}

.results-placeholder {
    background-color: rgb(255, 255, 255);
    border: 2px solid black;
    font-size: 1.2rem;
    padding: 10px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.input-container {
    position: relative; /* Allows the dropdown to position absolutely within this container */
    display: flex;
    flex-direction: column;
    width: 100%;
}


/* Desktop-specific styles */
@media (min-width: 1200px) {
    .results {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
    }

    .results-placeholder {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .top-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .player-pic2 {
        width: 36%; /* Larger size for desktop */
        margin-right: 2px;
        padding-left: 100px;
    }

    .stats-wrapper {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-top: 15px;
    }

    .stats-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }

    .stats-section, .stats-full-row {
        width: 100%;
        text-align: center; /* Center text in all sections */
        margin-bottom: 10px;
    }

    .single-stat {
        margin-top: 10px;
    }

    .stats-headings {
        font-size: 0.6rem;
    }

    .stats-results {
        font-size: 1.2rem;
    }

    .form {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .button-container {
        display: flex;
        justify-content: center; /* Centers the button horizontally */
        align-items: center; /* Centers the button vertically */
        height: 100%; /* Make the container take up the full height available */
    }
    
    .submit-button {
        width: 120px;
        margin-top: 17px;
    }
}

@media (min-width: 1600px) and (max-width: 1799px) {
    .player-pic2 {
        width: 42%;
        padding-left: 80px;
    }
}

@media (min-width: 1400px) and (max-width: 1599px) {
    .player-pic2 {
        width: 48%;
        padding-left: 30px;
    }
}

@media (min-width: 1201px) and (max-width: 1399px) {
    .player-pic2 {
        width: 55%;
        padding-left: 0px;
    }
}

/* Mobile-specific styles */
@media (max-width: 1200px) {
    .form {
        flex-direction: column;
        align-items: center;
    }

    .player-searcher {
        margin: 5px;
        margin-bottom: 10px;
        width: 90%;
    }

    .player-pic2 {
        width: 40%;
    }

    .intro-text, .form-label, .form-input, .submit-button, .results, .results-placeholder, .stats-headings {
        font-size: 0.6rem;
    }

    .form-input {
        margin-left: 0px;
    }

    .results {
        padding: 2px;
        padding-top: 1px;
        padding-bottom: 5px;
    }

    .results-placeholder {
        padding-left: 30px;
        padding-right: 30px;
    }

    .submit-button {
        width: 60%;
        margin-left: 0px;
    }

    .results-wrapper {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .stats-paragraph, .stats-headings, .search-item {
        font-size: 0.4rem;
    }

    .search-item {
        padding: 6px 0px;
    }

    .search-dropdown {
        margin-left: 0px;
    }

    .stats-headings {
        margin-top: 5px;
    }

    .input-container {
        margin-right: 0px; 
    }

    .submit-button {
        margin-top: 5px; 
        width: 100%;
    }
}

@media (min-width: 801px) and (max-width: 1200px) {
    .player-pic2 {
        width: 30%;
        padding-left: 0px;
    }
}
