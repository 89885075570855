.table {
    border: 3px solid rgb(0, 0, 0); /* table border here */
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    border-collapse: collapse;
    border-spacing: 0px;
}

.button-sort {
    margin-left: 30px;
    margin-right: 30px;
    border: 2px solid rgb(0, 0, 0);
    font-size: 1.2rem;
}

.gameweek-dropdown select {
    padding: 10px;
    width: 40%;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 5px;
    border: 3px solid #000000;
    margin-bottom: 5px;
    font-weight: bold;
}

.active-gameweek-input {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 35px;
}

.container-cardlist-tools {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.input-container-cardlist {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.input-wrapper {
  display: flex;
  align-items: center;
}

.input-wrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.minus-button {
  background-color: rgb(234, 115, 115);
}

.plus-button {
  background-color: rgb(106, 187, 106);
}

#gameweek {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: textfield; /* Firefox */
  appearance: none; /* Standard syntax */
}

#gameweek::-webkit-outer-spin-button,
#gameweek::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Removes spinners in Chrome */
}


 /* MOBILE */
 @media (max-width: 1200px) { 

  .table {
    border: 2px solid rgb(0, 0, 0); /* table border here */
  }
  
  .gameweek-dropdown select {
    width: 70%;
    font-size: 1rem;
    border-radius: 4px;
    border:2px solid #000000;
    margin-bottom: 0px;
    padding: 3px;
  }

  .button-sort {
    margin-left: 20px;
    margin-right: 15px;
    font-size: 0.7rem;
    border: 1px solid black;
  }

  .tool-text {
    font-size: 0.6rem;
  }

  .active-gameweek-input {
    font-size: 0.7rem;
    width: 20px;
  }

  .container-cardlist-tools {
    margin-bottom: 1px;
  }

  .input-wrapper button {
    width: 15px;
    height: 15px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    margin: 0 2px;
  }
    
}
