.top-10-sub-heading {
    background-color: white;
    margin-left: 9%;
    margin-right: 9%;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.player-pics {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 9%;
    margin-right: 9%;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 2px;
    border: 1px solid black;
}
  
.pics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 2px;
}

.top-10-title {
    text-align: left; 
    font-size: 1.4rem; 
    font-weight: bold;
    color: #000;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 1%;
}

.player-pic-top-10 {
    width: 100%; 
    height: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    object-fit: contain;
}

.player-pics-lists .pics-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.player-pics-lists .player-pic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9.5%;
    margin: 0 1px;
}

.player-pics-lists .player-pic-top-10 {
    width: 100%; 
    height: auto;
    aspect-ratio: 1; /* Forces a square aspect ratio */
    margin: 1px 1px 2px 1px;
    overflow: hidden;
}

.top-10-sub-heading p {
    margin-top: 0px;
    margin-bottom: 2px;
}

.team-pic-top-10 {
    height: 130px;
    margin: 2px 1px 3px 1px;
}

@media (max-width: 1600px) {
    .team-pic-top-10 {
        height: 100px;
    }
}

@media (max-width: 1400px) {
    .team-pic-top-10 {
        height: 90px;
    }
}
  
@media (max-width: 1200px) {
    .top-10-sub-heading {
        font-size: 0.6rem;
        margin-left: 3%;
        margin-right: 3%;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-top: 2px;
        padding-bottom: 0px;
    }

    .player-pics-lists .player-pic-container {
        width: 9.2%;
        margin: 0 1px;
    }

    .top-10-title {
        font-size: 0.7rem; 
        margin-bottom: 1px;
        margin-top: 1px;
        margin-left: 1%;
    }

    p {
        font-size: 0.6rem;
        margin-top: 1px;
        margin-bottom: 1px;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .player-pics {
        margin-left: 3%;
        margin-right: 3%;
        margin-top: 2px;
        margin-bottom: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
    }

    .player-pics-lists .player-pic-top-10 {
        margin-bottom: 2px;
        margin-top: 2px;
    }

    .team-pic-top-10 {
        height: 80px;
    }

    .pics-wrapper {
        margin-bottom: 0px;
    }
}

@media (max-width: 1000px) {
    .team-pic-top-10 {
        height: 70px;
    }
}

@media (max-width: 900px) {
    .team-pic-top-10 {
        height: 65px;
    }
}

@media (max-width: 800px) {
    .team-pic-top-10 {
        height: 60px;
    }
}

@media (max-width: 700px) {
    .team-pic-top-10 {
        height: 50px;
    }
}

@media (max-width: 600px) {
    .team-pic-top-10 {
        height: 45px;
    }
}

@media (max-width: 500px) {
    .team-pic-top-10 {
        height: 35px;
    }
}

@media (max-width: 450px) {
    .team-pic-top-10 {
        height: 30px;
    }
}
