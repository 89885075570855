#gameweek-dropdown {
    background-color: #f2f2f2; 
    color: #333; 
    outline: none;
}

#gameweek-dropdown:focus {
    border-color: #333;
    box-shadow: #333;
    outline: none;
}
  