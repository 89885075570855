.navbar {
  background-color: #333;
  padding: 5px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  position: relative;
}

.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.navbar-left {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-brand {
  color: white;
  font-size: 2.4rem;
}

.navbar-menu-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-menu li {
  display: inline;
  margin: 0 20px;
}

.navbar-menu li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 1.4rem;
}

.navbar-menu li a:hover {
  background-color: #6a6e66;
}

.navbar-menu li a.active {
  color: rgb(57, 187, 5);
}

.navbar-toggler {
  display: none; 
}


@media (max-width: 1250px) {
  .navbar-menu li {
    display: inline;
    margin: 0 5px;
    text-align: center;
  }

  .navbar-menu li a {
    padding: 5px;
    font-size: 1.2rem;
  }
}


@media (max-width: 900px) {
  .navbar {
    justify-content: space-between;
    padding: 2px;
    padding-left: 8px;
  }

  .navbar-toggler {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin-left: auto; 
  }

  .navbar-brand {
    font-size: 1.4rem;
  }

  .navbar-menu {
    display: none; 
  }

  .navbar-menu.open {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    z-index: 1;
  }

  .navbar-menu li {
    display: block;
    text-align: center;
    border-top: 1px solid #444;
    padding: 0; 
    cursor: pointer; 
  }

  .navbar-menu li a {
    display: block;
    width: 100%;
    box-sizing: border-box; 
    font-size: 1.2rem;

  }

  .navbar-menu li:hover {
    background-color: #6a6e66;
  }

  button {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
