.player-pics {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 9%;
  margin-right: 9%;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 2px;
  border: 1px solid black;
}

.pics-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0%;
  margin-right: 0%;
}

.player-stat {
  font-size: 1rem;
  font-weight: 800;
  color: black;
  text-align: center;
  margin: 0;

}

.player-stat-name {
  font-size: 1rem;
  color: black;
  text-align: center;
  margin: 0;
}

.search-sub-heading {
  background-color: white;
  margin-left: 9%;
  margin-right: 9%;
  font-size: 1.3rem;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search-sub-heading p {
  margin-top: 0px;
  margin-bottom: 2px;
}


@media (max-width: 1200px) {
  .search-sub-heading {
    font-size: 0.6rem;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 2px;
    padding-bottom: 0px;
  }

  .player-comparison-tool {
    margin-left: 3%;
    margin-right: 3%;
  }

  p {
    font-size: 0.6rem;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .player-stat {
    font-size: 0.6rem;
    font-weight: 600;
    padding: 0px;
  }

  .player-stat-name {
    display: none; /* Hide player names on mobile */
  }

}
