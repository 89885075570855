.fpl-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.checkbox-container {
  margin: 0px;
  flex: 0 1 auto;
}

.player-comparison-tool {
  margin-left: 9%;
  margin-right: 9%;
}
