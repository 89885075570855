.checkboxes-wrapper {
    border: 2px solid black;
    margin-left: 0%;
    margin-right: 0%;
    background-color: white;
}

.stats-box {
    padding-top: 5px;
    padding-bottom: 5px;
}

.form-label {
    margin-left: 5px;
}

/* Add a media query for mobile devices */
@media (max-width: 1200px) { /* Adjust the max-width as needed */

    .checkboxes-wrapper {
        border: 1px solid black;
    }

    .form-checkbox {
        transform: scale(0.5);
        margin: 0;
        vertical-align: -5px;  /*  horrible fix to align checkboxes */
    } 

    .form-label {
        font-size: 0.5rem;
    }

    .stats-box {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .form-label {
        margin-left: 0px;
    }
}