/* // try overflow: scroll */

.fixtures-table {
  table-layout: fixed;
  width: 100%;
}

/* Original FPL FDR Colours */
.original-fpl .fixture-info.difficulty-1 {
  background-color: rgb(159, 251, 159)
}

.original-fpl .difficulty-2 {
  background-color: rgb(179, 251, 208)
}

.original-fpl .difficulty-3 {
  background-color: rgb(249, 249, 203)
}

.original-fpl .difficulty-4 {
  background-color: rgb(255, 177, 128)
}

.original-fpl .difficulty-5 {
  background-color: rgb(250, 178, 178)
}


/* Custom FDR Colours */
.fixture-info.custom-difficulty-1 {
  background-color: rgb(159, 251, 159)
}

.fixture-info.custom-difficulty-2 {
  background-color: rgb(152, 241, 130)
}

.fixture-info.custom-difficulty-3 {
  background-color: rgb(162, 236, 143)
}

.fixture-info.custom-difficulty-4 {
  background-color: rgb(187, 219, 132)
}

.fixture-info.custom-difficulty-5 {
  background-color: rgb(223, 237, 153)
}

.fixture-info.custom-difficulty-6 {
  background-color: rgb(245, 245, 137)
}

.fixture-info.custom-difficulty-7 {
  background-color: rgb(250, 214, 143)
}

.fixture-info.custom-difficulty-8 {
  background-color: rgb(245, 189, 137)
}

.fixture-info.custom-difficulty-9 {
  background-color: rgb(240, 164, 164)
}

.fixture-info.custom-difficulty-10 {
  background-color: rgb(243, 143, 143)
}

.fixtures-table td {
  width: 20%;
  text-align: center;
}

th {
  font-size: 1.2em;
}

.table-header {
  background-color: rgba(188, 187, 187, 0.85);
}

.fdr-number {
  margin-top: 0px;
}


/* DESKTOP */
@media (min-width: 1200px) { 
  .fdr-column {
    width: 4%; 
    overflow-wrap: break-word;
  }
}

.fixture-column {
  overflow-wrap: break-word; 
  padding: 0px;
}

.cards {
    display: flex;
    justify-content: left;
    color: black;
    font-size: 1.5em;
    margin-left: 3%;
    margin-right: 3%;
    overflow-wrap: break-word; 
  }

  .team-name {
    color: black;
    font-size: 1.2em;
    font-weight: bold;
    overflow-wrap: break-word; 
  }

  .team-badge {
    height: 80px; 
    margin-top: 5px; 
    margin-bottom: 3px;
  }

  .fixture-info {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .fixture-badge {
    height: 80px; 
    margin-top: 10px; 
  }
  
  table {
    border-collapse: collapse;
    margin: 0 auto;
    background-color: white;
  }
  
  th,
  td {
    border-left: 2px solid rgba(0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0);
    text-align: center;
  }

  
/* TABLET */
@media (max-width: 1500px) { 
 
  .fdr-column {
    width: 8%; 
  }

  .team-column {
    width: 15%; 
  }
}


/* MOBILE */
@media (max-width: 1200px) {

  th,
  td {
    border-left: 1px solid rgba(0, 0, 0);
    border-top: 1px solid rgba(0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0);
    text-align: center;
  }

  .team-badge {
    height: 34px; 
    margin-top: 4px;
    margin-bottom: 1px;
  }

  .fixtures-table td {
    width: 20%;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 5px;
  }

  th {
    font-size: 0.5em;
  }

  .fixture-info {
    padding-top: 2px;
    padding-bottom: 1px;
  }

  .fixture-badge {
    height: 30px; 
    margin-top: 1px;
  }

  .fdr-column {
    width: 6%;
  }

  .team-column {
    width: 12%; 
  }

  .fixture-column {
    padding-top: 0px;
    font-size: 0.5rem;
  }

  .team-name {
    display: none;
    font-size: 0;
  }

  .page-break {
    display: none;
  }

  .fixtures-table td {
    padding: 0px;
  }

  .opponent-name {
    display: none;
    padding-top: 1px;
  }

  .fdr-number {
    font-size: 0.8em;
  }

  .cards {
    font-size: 0.5em;
    margin-left: 1%;
    margin-right: 1%;
  }
  
}


/* SMALL MOBILE */
@media (max-width: 420px) { 

  th {
    font-size: 0.2em;
  }

  .team-badge {
    height: 30px; 
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .fixture-badge {
    height: 25px; 
    margin-top: 3px;
  }

  .fdr-column {
    width: 7%;
  }

  .fixture-info {
    font-size: 0.6em;
  }

}